<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container">
      <div style="display: flex; gap: 16px; align-items: center; margin-bottom: 24px; flex-wrap: wrap; row-gap: 8px;">
        <h4 style="margin: 0;">Your Advertisements</h4>
        <router-link to="/save-ads" class="btn btn-primary">+ Add new advertisement</router-link>
      </div>
      <div style="text-align: center; margin: 20px 0;" v-if="loading"><a-spin /></div>
      <div class="d-flex justify-content-start flex-wrap listing-cards-container" v-if="loading === false" >
        <a-card hoverable class="listing-card" v-for="item in items" :key="item.id">
          <template #cover>
            <img src="../assets/img/placeholder_logo.png" v-if="item.images == null || item.images.length === 0" style="object-fit: contain; height: 200px; border: none; padding: 16px;" />
            <img :src="item.images[0]" v-if="item.images && item.images.length > 0" style="object-fit: cover; height: 200px;" />
          </template>
          <template class="ant-card-actions" #actions>
            <a-popover>
              <template #content>
                <p><a href="javascript:;" @click="openAd(item.id)">Edit Advertisement</a></p>
              </template>
              <EditOutlined key="edit" @click="openAd(item.id)" />
            </a-popover>
            <a-popover v-if="item.subscription_id != null">
              <template #content>
                  <p><router-link :to="{path: '/my-payment-methods'}">Update Payment Method</router-link></p>
                  <p><router-link :to="{name: 'change-ad-plan', params: {id: item.id}}">Change Plan</router-link></p>
              </template>
              <SettingOutlined key="setting" @click="changeAdPlan(item.id)" />
            </a-popover>
            <a-popover>
              <template #content>
                <p>
                  <a-pop-confirm title="Are you sure you want to delete this advertisement?" ok-text="Yes" cancel-text="No" @confirm="deleteAd(item.id)">
                    <a href="javascript:;">Delete Advertisment</a>
                  </a-pop-confirm>
                </p>
              </template>
              <a-pop-confirm title="Are you sure you want to delete this advertisement?" ok-text="Yes" cancel-text="No" @confirm="deleteAd(item.id)">
                <DeleteOutlined key="delete" />
              </a-pop-confirm>
            </a-popover>
          </template>
          <a-card-meta :title="item.business_name"><template #description></template></a-card-meta>
        </a-card>
      </div>
      <div v-if="items.length === 0 && loading === false">
        You do not have any listings.
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import axios from 'axios';
import { EditOutlined, SettingOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import ACard from 'ant-design-vue/lib/card';
import ASpin from 'ant-design-vue/lib/spin';
import APopConfirm from 'ant-design-vue/lib/popconfirm';
import APopover from 'ant-design-vue/lib/popover';
import 'ant-design-vue/lib/card/style/index.css';
import 'ant-design-vue/lib/spin/style/index.css';
import 'ant-design-vue/lib/popover/style/index.css';
import 'ant-design-vue/lib/button/style/index.css';
import 'ant-design-vue/lib/dropdown/style/index.css';
import 'ant-design-vue/lib/menu/style/index.css';

import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';
import Swal from 'sweetalert2';

export default {
  components: {
    BaseLayout,
    SubHeader,
    ACard,
    ASpin,
    APopConfirm,
    APopover,
    'a-card-meta': ACard.Meta,
    EditOutlined,
    SettingOutlined,
    DeleteOutlined,
  },
  data() {
    return {
      loading: false,
      items: []
    }
  },
  async mounted() {
    document.title = 'My Advertisements - Mane Street Market';
    window.scrollTo(0, 0);
    await this.loadItems();
  },
  methods: {
    async loadItems() {
      if (this.$route.query.upgrade_completed) {
        Swal.fire({
          title: 'Completed',
          text: 'Plan is upgraded!'
        });
      }
      this.loading = true;
      try {
        let response = await axios.get('/api/my-ads');
        this.items = response.data.items;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    openAd(id) {
      this.$router.push({path: '/save-ads', query: {id: id}});
    },
    changeAdPlan(id) {
      this.$router.push({name: 'change-ad-plan', params: { id }});
    },
    async deleteAd(id) {
      this.loading = true;
      try {
        await axios.delete(`/api/ads/${id}`);
        await this.loadItems();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .listing-cards-container {
    gap: 10px;
  }

  .listing-card {
    width: calc(33% - 10px * 2 / 3);
  }

  @media screen and (max-width: 480px) {
    .listing-card {
      width: calc(50% - 10px / 2);
    }
  }
</style>
